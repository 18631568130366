
export default {
  data () {
    return {
      header: {
        title: '站内服务 & 投诉举报',
        subtitle: '如有对平台使用等相关问题，请与网站运营部联系。'
      },
      contacts: [{
        icon: 'mdi-phone-in-talk-outline',
        text: '025-6589 4582'
      }, {
        icon: 'mdi-email-outline',
        text: 'wyjzmdg@163.com'
      }],
      qrcodes: [{
        icon: '',
        text: '微博',
        image: ''
      }, {
        icon: '',
        text: '视频号',
        image: ''
      }, {
        icon: '',
        text: '微信公众号',
        image: ''
      }, {
        icon: '',
        text: '抖音账号',
        image: ''
      }]
    }
  }
}
