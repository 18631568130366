<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    />
    <about-contact
      :title="header.title"
      :subtitle="header.subtitle"
      space="40"
    />
    <about-medias />
    <about-message
      ref="wdAboutMsg"
      back-color="grey lighten-4"
      @submit="onMessageSubmit"
    />
    <dialog-alert ref="dlgAlert" />
    <!-- <section-foot /> -->
  </section>
</template>

<script>
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'
  import mixAboutBanner from '@/pages/mixins/about/mix.about.banner'
  import mixAboutBoot from '@/pages/mixins/about/mix.about.boot'

  export default {
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue'),
      AboutContact: () => import('@/pages/sections/about/AboutContact.vue'),
      AboutMedias: () => import('@/pages/sections/about/AboutMedias.vue'),
      AboutMessage: () => import('@/pages/sections/about/AboutMessage.vue'),
      // SectionFoot: () => import('@/pages/sections/Foot.vue')
    },
    metaInfo: { title: '联系我们' },
    mixins: [
      mixAboutBanner,
      mixAboutBoot
    ],
    data () {
      return {
        userInfo: {}
      }
    },
    created () {
      this.userInfo = user.info.get()

      this.initBannerParams({
        type: web.comm.BannerTypes.ABOUT_MAJOR
      })
      this.loadBanners()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      onMessageSubmit (ev) {
        // console.log({ ev })
        const me = this
        const executing = function () {}
        const executed = function (res) {
          if (res.status) {
            const wd = me.$refs.wdAboutMsg
            if (wd) {
              wd.reset()
            }
            me.showAlert('success', '在线留言提交成功')
          } else {
            const message = `${res.data.code}-${res.data.desc}`
            me.showAlert('error', message)
          }
        }

        const data = {
          userId: this.userInfo.userId || '',
          username: ev.name,
          mobile: ev.phone,
          email: ev.email || '',
          organization: ev.company || '',
          department: ev.department || '',
          content: ev.memo || ''
        }

        web.guestbook.create({
          data,
          executing,
          executed
        })
      }
    }
  }
</script>
